<template>
  <ul>
    <li
      v-for="(message, index) in messages"
      :key="message.id"
      class="flex flex-col mx-4 border-t border-gray-300 pt-3"
      :class="{
        'mt-2 border-none': index === 0,
        'mt-4': index !== 0,
        'pb-3': index === messages.length - 1,
      }">
      <div class="text-gray-500 flex items-end uppercase">
        <p class="text-black text-xs mr-1 mb-2">
          <span
            v-if="message.user"
            v-text="`${message.user.name} • `">
          </span>
          <span
            v-if="!message.createdAt.isSame(now, 'day')"
            v-text="message.createdAt.format('L')">
          </span>
          <span
            v-else
            v-text="message.createdAt.format('LT')">
          </span>
        </p>
      </div>
      <div
        class="flex"
        v-if="message.user !== null">
        <div class="flex">
          <img
            v-if="message.user.avatar !== null"
            :src="message.user.avatar.small"
            :alt="$t('general.label_profile_picture')"
            class="h-7 w-7 rounded-full"
          >
          <img
            v-else
            src="@/assets/images/avatar_placeholder.svg"
            :alt="$t('general.label_profile_picture')"
            class="h-7 w-7 rounded-full"
          >
        </div>
        <div class="bg-gray-200 w-full rounded-lg ml-2 mb-1">
          <p
            v-if="message.type === MessageTypes.TEXT"
            v-text="message.content"
            class="px-3 py-2"></p>
          <img
            v-else-if="message.type === MessageTypes.IMAGE"
            :src="message.content.medium"
            :alt="$t('messages_list.label_message_picture')"
            class="w-full rounded-lg"
          >
          <video
            v-else-if="message.type === MessageTypes.VIDEO"
            class="w-full"
            controls
            :poster="message.content.thumbnail"
          >
            <source
              :src="message.content.file"
              type="video/mp4">
          </video>
        </div>
      </div>
      <div
        v-else
        class="flex">
        <p
          v-text="message.content"
          class="uppercase text-sm"></p>
      </div>
    </li>
  </ul>
</template>

<script>
  import MessageTypes from '@/constants/MessageTypes';
  import time from '@/mixins/time';

  export default {
    name: 'MessagesList',
    mixins: [time],
    props: {
      messages: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        MessageTypes,
      };
    },
  };
</script>
