<template>
  <div class="flex flex-col">
    <nav class="flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end">
      <v-back :fallback="$router.resolve({ name: 'incidents-overview'})"/>
      <p
        v-if="incident !== null"
        v-text="incident.title !== null ? incident.title : incident.incidentTypes[0].name"
        class="font-semibold truncate px-2"
      ></p>
      <div v-if="incident !== null">
        <button
          v-if="incident.canResolve"
          v-text="$t('the_incident_detail.button_resolve')"
          class="btn btn-blue rounded-full py-1 px-2"
          :class="{'btn-loading': resolving}"
          :disabled="resolving"
          @click="openConfirm"
        ></button>
        <p
          v-if="!incident.canResolve && incident.resolvedAt !== null"
          v-text="$t('the_incident_detail.label_resolved')"
          class="bg-gray-200 text-blue-500 py-1 px-2 rounded-full"
        ></p>
      </div>
    </nav>
    <div
      class="flex-grow flex flex-col"
      v-if="incident !== null">
      <router-link
        to="#"
        class="detail-field max-w-full focus:outline-none focus:bg-gray-100">
        <div class="flex items-center w-1/3 mr-5">
          <img
            src="@/assets/images/avatar_placeholder.svg"
            class="h-7 w-7 mr-2">
          <p
            v-text="$t('the_incident_detail.label_victim')"
            class="uppercase truncate"></p>
        </div>
        <div class="flex items-center justify-end w-1/2">
          <p
            v-if="incident.victims.length === 0"
            v-text="$t('the_incident_detail.label_unknown')"
            class="mr-1 truncate"
          ></p>
          <p
            v-else
            v-text="victims"
            class="mr-1 truncate"
          ></p>
          <img
            src="@/assets/images/ic_chevron_right_blue.svg"
            class="h-4 w-4">
        </div>
      </router-link>
      <router-link
        to="#"
        class="detail-field max-w-full focus:outline-none focus:bg-gray-100">
        <div class="flex items-center w-1/2 mr-5">
          <img
            v-if="incident.user !== null && incident.user.avatar !== null"
            :src="incident.user.avatar.small"
            :alt="$t('general.label_profile_picture')"
            class="h-7 w-7 rounded-full mr-2"
          >
          <img
            v-else
            src="@/assets/images/avatar_placeholder.svg"
            class="h-7 w-7 mr-2"
            :alt="$t('general.label_profile_picture')"
          >
          <p
            v-text="$t('the_incident_detail.label_reporter')"
            class="uppercase truncate"></p>
        </div>
        <div class="flex items-center justify-end w-1/2">
          <p
            v-if="incident.user !== null"
            v-text="incident.user.name"
            class="mr-1 truncate"></p>
          <p
            v-else
            v-text="$t('the_incident_detail.label_unknown')"
            class="mr-1 truncate"></p>
          <img
            src="@/assets/images/ic_chevron_right_blue.svg"
            class="h-4 w-4">
        </div>
      </router-link>
      <router-link
        :tag="incident.canAssign ? 'a' : 'div'"
        :to="incident.canAssign ? {name: 'incident-assign', params: {id} } : ''"
        class="detail-field max-w-full focus:outline-none focus:bg-gray-100">
        <div class="flex items-center w-1/2 mr-5">
          <img
            src="@/assets/images/avatar_placeholder.svg"
            class="h-7 w-7 mr-2">
          <p
            v-text="$t('the_incident_detail.label_assigned_users')"
            class="uppercase truncate"></p>
        </div>
        <div class="flex items-center w-1/2 justify-end">
          <div
            v-if="incident.assignedUsers.length > 0"
            class="flex items-center justify-end max-w-full"
          >
            <p
              v-text="incident.assignedUsers.map(user => user.shortName).join(' • ')"
              class="mr-2 truncate"
            ></p>
            <img
              v-if="incident.canAssign"
              src="@/assets/images/ic_chevron_right_blue.svg"
              class="h-4 w-4">
          </div>
          <p
            v-else-if="incident.canAssign"
            v-text="$t('general.button_assign')"
            class="btn-blue px-3 py-1 rounded-full uppercase text-xs"
          ></p>
        </div>
      </router-link>
      <create-message-form
        v-if="incident !== null && incident.canCreateMessage"
        :incident="incident"/>
      <v-scrollable ref="scrollable">
        <messages-list
          v-if="messages !== null"
          :messages="messages"
          class="flex-grow"/>
        <v-loading v-if="incident && loading"/>
      </v-scrollable>
    </div>
    <v-loading
      v-if="incident === null && loading"
      class="flex-grow"/>
    <v-error
      v-if="error !== null"
      v-model="error"/>
    <v-confirm
      v-if="showConfirm"
      @confirm="resolve"
      @cancel="closeConfirm"
      :header="$t('the_incident_detail.label_header_confirm_resolve')"
      :body="$t('the_incident_detail.label_body_confirm_resolve')"
      :confirm="$t('the_incident_detail.button_resolve')"
    />
  </div>
</template>

<script>
  import infinityScroll from '@/mixins/infinityScroll';
  import EventBus from '@/eventbus';
  import VLoading from '@/components/common/VLoading.vue';
  import VError from '@/components/common/VError.vue';
  import VConfirm from '@/components/common/VConfirm.vue';
  import MessagesList from '@/components/lists/MessagesList.vue';
  import VScrollable from '@/components/common/VScrollable.vue';
  import CreateMessageForm from '@/components/forms/CreateMessageForm.vue';
  import VBack from '@/components/common/VBack';

  export default {
    name: 'TheIncidentDetail',
    components: {
      VBack,
      VLoading,
      VError,
      VConfirm,
      MessagesList,
      VScrollable,
      CreateMessageForm,
    },
    mixins: [infinityScroll],
    props: {
      id: { type: String, default: null },
    },
    data() {
      return {
        loading: false,
        error: null,
        showConfirm: false,
        resolving: false,
      };
    },
    computed: {
      incident() {
        return this.$store.getters['incident/item'];
      },
      messages() {
        return this.$store.getters['message/list'];
      },
      victims() {
        return this.incident.victims.map((victim) => {
          if (!victim.user) {
            return this.$t('the_incident_detail.label_unknown');
          }

          return victim.user.shortName;
        }).join(' • ');
      },
    },
    watch: {
      incident(newIncident, oldIncident) {
        if (this.incident === null || (oldIncident && oldIncident.id === this.incident.id)) {
          return;
        }

        EventBus.$emit('center_changed', {
          lat: this.incident.lat,
          lng: this.incident.long,
        });

        EventBus.$emit('zoom_changed', 20);
      },
      id() {
        this.detachInfiniteScroll();

        this.$store.commit('incident/clearItem');
        this.$store.commit('message/clearList');
        this.error = null;

        this.loadData();
      },
    },
    created() {
      this.loadData();
    },
    beforeDestroy() {
      this.$store.commit('incident/clearItem');
      this.$store.commit('message/clearList');
    },
    methods: {
      loadData() {
        this.loading = true;

        this.$store
          .dispatch('incident/loadById', this.id)
          .then(this.loadMessages)
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      loadMessages() {
        if (!this.incident.canLoadMessages) {
          this.loading = false;

          return;
        }

        this.$store
          .dispatch('message/loadByIncidentId', this.id)
          .then(() => {
            this.loading = false;

            this.$nextTick().then(() => {
              this.attachInfiniteScroll(this.$refs.scrollable, () => {
                this.loadMessages();
              });
            });
          })
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      openConfirm() {
        this.showConfirm = true;
      },
      closeConfirm() {
        this.showConfirm = false;
      },
      resolve() {
        if (!this.incident.canResolve) {
          return;
        }

        this.showConfirm = false;
        this.resolving = true;

        this.$store.dispatch('incident/resolve', this.incident)
          .then(() => {
            this.resolving = false;
          });
      },
    },
  };
</script>
