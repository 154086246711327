<template>
  <div>
    <ul class="list-disc list-inside">
      <li
        v-if="error"
        v-text="error"
        class="form-error">
      </li>
    </ul>
    <div class="ml-4">
      <form
        class="w-full flex items-center border-b border-blue-500 py-3 pr-4"
        @submit.prevent="createMessage">
        <input
          type="text"
          autofocus
          ref="input"
          v-model="message.content"
          name="message"
          class="form-input-blocked border border-blue-500 bg-gray-100 mr-1"
          :placeholder="$t('create_message_form.placeholder_create_message')"
          v-validate="'required'"
          data-vv-validate-on="blur"
          :disabled="loading || !incident.canCreateMessage"
          :class="{'bg-gray-200': loading}"
        >
        <button
          type="submit"
          class="btn-round ml-1 flex-none"
          :disabled="disabled"
          :class="{
            'btn-loading': loading,
            'btn-blue': !disabled,
            'btn-blue-light': disabled
          }"
          @click="createMessage"
        >
          <img
            v-if="!loading"
            src="@/assets/images/ic_chevron_right.svg"
            class="w-3 h-3"
            :alt="$t('create_message_form.button_send')"
            :title="$t('create_message_form.button_send')"
          >
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  import MessageTypes from '@/constants/MessageTypes';
  import validator from '@/mixins/validator';
  import Incident from '@/models/Incident';
  import Message from '@/models/Message';

  export default {
    name: 'CreateMessageForm',
    mixins: [validator],
    props: {
      incident: {
        type: Incident,
        required: true,
      },
    },
    data() {
      return {
        message: new Message(),
        loading: false,
        error: null,
      };
    },
    computed: {
      disabled() {
        return this.loading || !this.incident.canCreateMessage || !this.message.content;
      },
    },
    methods: {
      createMessage() {
        if (this.disabled) {
          return;
        }

        this.$validator.validate().then(() => {
          this.error = null;

          if (this.$validator.errors.items.length > 0) {
            return;
          }

          this.loading = true;
          this.message.type = MessageTypes.TEXT;
          this.message.incident = this.incident;

          this.$store
            .dispatch('message/create', this.message)
            .then(() => {
              this.message = new Message();
              this.loading = false;

              this.$nextTick(() => this.$refs.input.focus());
            })
            .catch(this.handleError);
        });
      },
      handleError(error) {
        this.loading = false;

        if (!error.response) {
          this.error = this.$t('general.label_error');

          return;
        }

        if (error.response.data.message) {
          this.error = error.response.data.message;
        }

        if (error.response.data.errors && error.response.data.errors.content) {
          [this.error] = error.response.data.errors.content;
        }
      },
    },
  };
</script>
