var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col" },
    [
      _c(
        "nav",
        {
          staticClass:
            "flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end"
        },
        [
          _c("v-back", {
            attrs: {
              fallback: _vm.$router.resolve({ name: "incidents-overview" })
            }
          }),
          _vm.incident !== null
            ? _c("p", {
                staticClass: "font-semibold truncate px-2",
                domProps: {
                  textContent: _vm._s(
                    _vm.incident.title !== null
                      ? _vm.incident.title
                      : _vm.incident.incidentTypes[0].name
                  )
                }
              })
            : _vm._e(),
          _vm.incident !== null
            ? _c("div", [
                _vm.incident.canResolve
                  ? _c("button", {
                      staticClass: "btn btn-blue rounded-full py-1 px-2",
                      class: { "btn-loading": _vm.resolving },
                      attrs: { disabled: _vm.resolving },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("the_incident_detail.button_resolve")
                        )
                      },
                      on: { click: _vm.openConfirm }
                    })
                  : _vm._e(),
                !_vm.incident.canResolve && _vm.incident.resolvedAt !== null
                  ? _c("p", {
                      staticClass:
                        "bg-gray-200 text-blue-500 py-1 px-2 rounded-full",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("the_incident_detail.label_resolved")
                        )
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.incident !== null
        ? _c(
            "div",
            { staticClass: "flex-grow flex flex-col" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "detail-field max-w-full focus:outline-none focus:bg-gray-100",
                  attrs: { to: "#" }
                },
                [
                  _c("div", { staticClass: "flex items-center w-1/3 mr-5" }, [
                    _c("img", {
                      staticClass: "h-7 w-7 mr-2",
                      attrs: {
                        src: require("@/assets/images/avatar_placeholder.svg")
                      }
                    }),
                    _c("p", {
                      staticClass: "uppercase truncate",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("the_incident_detail.label_victim")
                        )
                      }
                    })
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-end w-1/2" },
                    [
                      _vm.incident.victims.length === 0
                        ? _c("p", {
                            staticClass: "mr-1 truncate",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("the_incident_detail.label_unknown")
                              )
                            }
                          })
                        : _c("p", {
                            staticClass: "mr-1 truncate",
                            domProps: { textContent: _vm._s(_vm.victims) }
                          }),
                      _c("img", {
                        staticClass: "h-4 w-4",
                        attrs: {
                          src: require("@/assets/images/ic_chevron_right_blue.svg")
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass:
                    "detail-field max-w-full focus:outline-none focus:bg-gray-100",
                  attrs: { to: "#" }
                },
                [
                  _c("div", { staticClass: "flex items-center w-1/2 mr-5" }, [
                    _vm.incident.user !== null &&
                    _vm.incident.user.avatar !== null
                      ? _c("img", {
                          staticClass: "h-7 w-7 rounded-full mr-2",
                          attrs: {
                            src: _vm.incident.user.avatar.small,
                            alt: _vm.$t("general.label_profile_picture")
                          }
                        })
                      : _c("img", {
                          staticClass: "h-7 w-7 mr-2",
                          attrs: {
                            src: require("@/assets/images/avatar_placeholder.svg"),
                            alt: _vm.$t("general.label_profile_picture")
                          }
                        }),
                    _c("p", {
                      staticClass: "uppercase truncate",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("the_incident_detail.label_reporter")
                        )
                      }
                    })
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-end w-1/2" },
                    [
                      _vm.incident.user !== null
                        ? _c("p", {
                            staticClass: "mr-1 truncate",
                            domProps: {
                              textContent: _vm._s(_vm.incident.user.name)
                            }
                          })
                        : _c("p", {
                            staticClass: "mr-1 truncate",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("the_incident_detail.label_unknown")
                              )
                            }
                          }),
                      _c("img", {
                        staticClass: "h-4 w-4",
                        attrs: {
                          src: require("@/assets/images/ic_chevron_right_blue.svg")
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass:
                    "detail-field max-w-full focus:outline-none focus:bg-gray-100",
                  attrs: {
                    tag: _vm.incident.canAssign ? "a" : "div",
                    to: _vm.incident.canAssign
                      ? { name: "incident-assign", params: { id: _vm.id } }
                      : ""
                  }
                },
                [
                  _c("div", { staticClass: "flex items-center w-1/2 mr-5" }, [
                    _c("img", {
                      staticClass: "h-7 w-7 mr-2",
                      attrs: {
                        src: require("@/assets/images/avatar_placeholder.svg")
                      }
                    }),
                    _c("p", {
                      staticClass: "uppercase truncate",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("the_incident_detail.label_assigned_users")
                        )
                      }
                    })
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex items-center w-1/2 justify-end" },
                    [
                      _vm.incident.assignedUsers.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-end max-w-full"
                            },
                            [
                              _c("p", {
                                staticClass: "mr-2 truncate",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.incident.assignedUsers
                                      .map(function(user) {
                                        return user.shortName
                                      })
                                      .join(" • ")
                                  )
                                }
                              }),
                              _vm.incident.canAssign
                                ? _c("img", {
                                    staticClass: "h-4 w-4",
                                    attrs: {
                                      src: require("@/assets/images/ic_chevron_right_blue.svg")
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        : _vm.incident.canAssign
                        ? _c("p", {
                            staticClass:
                              "btn-blue px-3 py-1 rounded-full uppercase text-xs",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("general.button_assign")
                              )
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _vm.incident !== null && _vm.incident.canCreateMessage
                ? _c("create-message-form", {
                    attrs: { incident: _vm.incident }
                  })
                : _vm._e(),
              _c(
                "v-scrollable",
                { ref: "scrollable" },
                [
                  _vm.messages !== null
                    ? _c("messages-list", {
                        staticClass: "flex-grow",
                        attrs: { messages: _vm.messages }
                      })
                    : _vm._e(),
                  _vm.incident && _vm.loading ? _c("v-loading") : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.incident === null && _vm.loading
        ? _c("v-loading", { staticClass: "flex-grow" })
        : _vm._e(),
      _vm.error !== null
        ? _c("v-error", {
            model: {
              value: _vm.error,
              callback: function($$v) {
                _vm.error = $$v
              },
              expression: "error"
            }
          })
        : _vm._e(),
      _vm.showConfirm
        ? _c("v-confirm", {
            attrs: {
              header: _vm.$t(
                "the_incident_detail.label_header_confirm_resolve"
              ),
              body: _vm.$t("the_incident_detail.label_body_confirm_resolve"),
              confirm: _vm.$t("the_incident_detail.button_resolve")
            },
            on: { confirm: _vm.resolve, cancel: _vm.closeConfirm }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }