var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    _vm._l(_vm.messages, function(message, index) {
      return _c(
        "li",
        {
          key: message.id,
          staticClass: "flex flex-col mx-4 border-t border-gray-300 pt-3",
          class: {
            "mt-2 border-none": index === 0,
            "mt-4": index !== 0,
            "pb-3": index === _vm.messages.length - 1
          }
        },
        [
          _c("div", { staticClass: "text-gray-500 flex items-end uppercase" }, [
            _c("p", { staticClass: "text-black text-xs mr-1 mb-2" }, [
              message.user
                ? _c("span", {
                    domProps: { textContent: _vm._s(message.user.name + " • ") }
                  })
                : _vm._e(),
              !message.createdAt.isSame(_vm.now, "day")
                ? _c("span", {
                    domProps: {
                      textContent: _vm._s(message.createdAt.format("L"))
                    }
                  })
                : _c("span", {
                    domProps: {
                      textContent: _vm._s(message.createdAt.format("LT"))
                    }
                  })
            ])
          ]),
          message.user !== null
            ? _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "flex" }, [
                  message.user.avatar !== null
                    ? _c("img", {
                        staticClass: "h-7 w-7 rounded-full",
                        attrs: {
                          src: message.user.avatar.small,
                          alt: _vm.$t("general.label_profile_picture")
                        }
                      })
                    : _c("img", {
                        staticClass: "h-7 w-7 rounded-full",
                        attrs: {
                          src: require("@/assets/images/avatar_placeholder.svg"),
                          alt: _vm.$t("general.label_profile_picture")
                        }
                      })
                ]),
                _c(
                  "div",
                  { staticClass: "bg-gray-200 w-full rounded-lg ml-2 mb-1" },
                  [
                    message.type === _vm.MessageTypes.TEXT
                      ? _c("p", {
                          staticClass: "px-3 py-2",
                          domProps: { textContent: _vm._s(message.content) }
                        })
                      : message.type === _vm.MessageTypes.IMAGE
                      ? _c("img", {
                          staticClass: "w-full rounded-lg",
                          attrs: {
                            src: message.content.medium,
                            alt: _vm.$t("messages_list.label_message_picture")
                          }
                        })
                      : message.type === _vm.MessageTypes.VIDEO
                      ? _c(
                          "video",
                          {
                            staticClass: "w-full",
                            attrs: {
                              controls: "",
                              poster: message.content.thumbnail
                            }
                          },
                          [
                            _c("source", {
                              attrs: {
                                src: message.content.file,
                                type: "video/mp4"
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            : _c("div", { staticClass: "flex" }, [
                _c("p", {
                  staticClass: "uppercase text-sm",
                  domProps: { textContent: _vm._s(message.content) }
                })
              ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }