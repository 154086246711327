var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("ul", { staticClass: "list-disc list-inside" }, [
      _vm.error
        ? _c("li", {
            staticClass: "form-error",
            domProps: { textContent: _vm._s(_vm.error) }
          })
        : _vm._e()
    ]),
    _c("div", { staticClass: "ml-4" }, [
      _c(
        "form",
        {
          staticClass:
            "w-full flex items-center border-b border-blue-500 py-3 pr-4",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.createMessage($event)
            }
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.message.content,
                expression: "message.content"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            ref: "input",
            staticClass:
              "form-input-blocked border border-blue-500 bg-gray-100 mr-1",
            class: { "bg-gray-200": _vm.loading },
            attrs: {
              type: "text",
              autofocus: "",
              name: "message",
              placeholder: _vm.$t(
                "create_message_form.placeholder_create_message"
              ),
              "data-vv-validate-on": "blur",
              disabled: _vm.loading || !_vm.incident.canCreateMessage
            },
            domProps: { value: _vm.message.content },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.message, "content", $event.target.value)
              }
            }
          }),
          _c(
            "button",
            {
              staticClass: "btn-round ml-1 flex-none",
              class: {
                "btn-loading": _vm.loading,
                "btn-blue": !_vm.disabled,
                "btn-blue-light": _vm.disabled
              },
              attrs: { type: "submit", disabled: _vm.disabled },
              on: { click: _vm.createMessage }
            },
            [
              !_vm.loading
                ? _c("img", {
                    staticClass: "w-3 h-3",
                    attrs: {
                      src: require("@/assets/images/ic_chevron_right.svg"),
                      alt: _vm.$t("create_message_form.button_send"),
                      title: _vm.$t("create_message_form.button_send")
                    }
                  })
                : _vm._e()
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }